import  { createRouter, createWebHistory } from 'vue-router'
import i18n from "../utils/i18n";
const routes = [
  {
    path: '/',
    name: 'Login',
    component:()=>import('../views/login.vue')
  },
  {
    path: '/balance',
    name: '/balance',
    // component:NoticeDetails
    component:()=>import('../views/balance.vue')
  },
  /*
  {
    path: '/home',
    name: 'Home',
    component:()=>import('../views/Home.vue')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component:()=>import('../views/Maintenance.vue')

  },
  {
    path: '/myTeam',
    name: 'MyTeam',
    // component:MyTeam,
    component:()=>import('../views/my-team.vue')


  },{
    path: '/assetDetails',
    name: 'AssetDetails',
    // component:AssetDetails,
    component:()=>import('../views/asset-details.vue')


  },{
    path: '/share',
    name: 'Share',
    // component:Share,
    component:()=>import('../views/share.vue')


  },{
    path: '/switchLanguage',
    name: 'SwitchLanguage',
    // component:SwitchLanguage,
    component:()=>import('../views/switch-language.vue')


  },{
    path: '/noticeList',
    name: 'NoticeList',
    // component:NoticeList
    component:()=>import('../views/notice-list.vue')

  },{
    path: '/noticeDetails',
    name: 'NoticeDetails',
    // component:NoticeDetails
    component:()=>import('../views/notice-details.vue')
  },
  {
    path: '/reservationDetails',
    name: 'reservationDetails',
    // component:NoticeDetails
    component:()=>import('../views/reservation-details.vue')
  }, {
    path: '/cancelReinvestment',
    name: 'cancelReinvestment',
    // component:NoticeDetails
    component:()=>import('../views/cancel-reinvestment.vue')
  },{
    path: '/interestReward',
    name: '/interestReward',
    // component:NoticeDetails
    component:()=>import('../views/interest-reward.vue')
  },
  {
    path: '/dynamicRewards',
    name: '/dynamicRewards',
    // component:NoticeDetails
    component:()=>import('../views/dynamic-rewards.vue')
  },
  */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title =i18n.global.t( to.meta.title)
  }
  next()
})
export default router
